export { default as websiteLogoAr } from './websiteLogoAr.svg';
export { default as CarPlaceholderGif } from './imgPlaceholder.gif';
export const websiteLogoEn =
    'https://website-images.sylndr.com/sylndrLogoDarkBlue.d80431a253eb54c5aeb2-min.png';
export { default as websiteLogoArWhite } from './websiteLogoArWhite.svg';
export const HeaderCar = 'https://website-images.sylndr.com/car.png';
export { default as FooterBackground } from './background.svg';

export * from './AboutUs';
export * from './CarFinance';
export * from './FAQs';
export * from './financeApplication';
export * from './Icons';
export * from './mobile-navigation';
export * from './Acquisition';
export * from './maps';
export * from './VisitDetails';
export * from './purchaseCarSteps';
//////////used threshold

//about us page
export { default as FormHeaderArrowBack } from './arrowBack.svg';
export { default as ArrowHead } from './arrowHead.svg';

//car profile
export { default as ComfortConvenienceIconImg } from './ComfortConvenience.svg';
export { default as DimensionsCapacityIconImg } from './DimensionsCapacity.svg';
export { default as EntertainmentCommunicationIconImg } from './EntertainmentCommunication.svg';
export { default as ExteriorIconImg } from './ExteriorIcon.svg';
export { default as InteriorIconImg } from './InteriorIcon.svg';
export { default as PerformanceFeatureIconImg } from './PerformanceFeature.svg';
export { default as SafetyIconImg } from './SafetyIcon.svg';
export { default as SylndrCertified } from './WhySylndr/sylndr-certified.svg';
export { default as SylndrCertifiedIcon } from './WhySylndr/sylndr-certified.svg';
export { default as ImperfectionCheckMark } from './imperfectionCheckMark.png';
export { default as FaultyCheckMark } from './faultyCheckMark.png';
export { default as FixedMark } from './FixedIcon.png';
export { default as ArrowDownWithTail } from './arrow-down.svg';
export { default as carFrame } from './carFrame.svg';
export { default as engine } from './engine.svg';
export { default as switchHorizontal } from './switch-horizontal.svg';

export { default as ElectricalFaulty } from './imperfectionsIcons/electricalFaulty.svg';
export { default as ExteriorFaulty } from './imperfectionsIcons/exteriorFaulty.svg';
export { default as ExteriorGood } from './imperfectionsIcons/exteriorGood.svg';
export { default as InteriorFaulty } from './imperfectionsIcons/interiorFaulty.svg';
export { default as InteriorGood } from './imperfectionsIcons/interiorGood.svg';
export { default as MechanicalFaulty } from './imperfectionsIcons/mechanicalFaulty.svg';
export { default as MechanicalGood } from './imperfectionsIcons/mechanicalGood.svg';
export { default as SuspensionFaulty } from './imperfectionsIcons/suspensionFaulty.svg';
export { default as SuspensionGood } from './imperfectionsIcons/suspensionGood.svg';
export { default as TyreFaulty } from './imperfectionsIcons/tyreFaulty.svg';
export { default as TyreGood } from './imperfectionsIcons/tyreGood.svg';
export { default as ElectricalGood } from './imperfectionsIcons/electricalGood.svg';

export { default as CalendarCardIcon } from './Icons/calenderIcon.svg';
export { default as Speedometer } from './Icons/speedometer.svg';
export { default as Speedometer2 } from './speedometer2.svg';

export { default as allLegalities } from './allLegalitiesAreChecked.svg';
export { default as carsFullyRef } from './carsFullyRefurbished.svg';
export { default as nintyDays } from './nineDaysWarranty.svg';
export { default as noMajorAcc } from './noMajorAccidents.svg';
export { default as sevenDays } from './SevenDaysReturn.svg';
export { default as zeroRepair } from './zeroRepaircost.svg';

export { default as TransmissionIcon } from './Icons/transmissionIcon.svg';
export { default as TransmissionIcon2 } from './TransmissionIcon2.svg';

export { default as ArrowDownWhiteWithTail } from './arrow-down-white.svg';
export { default as WhiteCheckmark } from './whiteCheckmark.svg';
export { default as NonAccedintalIcon } from './non-accidental-icon.svg';
export { default as AlertCircleYellow } from './alert-circle-yellow.svg';
export { default as CheckCircleGreenGradient } from './check-circle-green-gradient.svg';
export { default as InfoCircleBlue } from './info-circle-blue.svg';
export { default as AlertCircleInactive } from './alert-circle-inactive.svg';
export { default as CheckCircleInactive } from './check-circle-inactive.svg';
export { default as NotesCircleInactive } from './info-circle-inactive.svg';
export { default as InteriorIcon } from './interior-icon.svg';
export { default as CheckCircleGreen } from './check-circle-green.svg';
export { default as CheckCircleGreenOutlined } from './check-circle-green-outlined.svg';
export { default as GrouppedGreenStarts } from './report-stars.svg';
export { default as CarFactoryPaint } from './Factory-Paint.gif';
export { default as NonTamperedIcon } from './non-tampered-icon.svg';
export { default as LegalityCheckedIcon } from './legalities-checked-icon.svg';
export { default as ZeroCostRepair } from './zero-cost-repair.svg';
export { default as HighlightsIcon } from './highlights-icon.svg';

//Gallery
export { default as NavLeft } from './Gallery/navLeft.svg';
export { default as NavRight } from './Gallery/navRight.svg';

export { default as Deg360 } from './Gallery/360.svg';
export { default as GalleryIcon } from './Gallery/galleryIcon.svg';
export { default as X } from './X.svg';
export { default as FullScreenICon } from './Gallery/fullScreenIcon.svg';
export { default as ImperfectionsIcon } from './Gallery/imperfectionsIcon.svg';
export { default as CheckVerifiedWhite } from './Gallery/checkVerified.svg';
export { default as InspectionPoints } from './badges/inspectionPoints.svg';

export { default as HelpIcon } from './helpIcon.svg';
export { default as GreenBill } from './greenBill.svg';
export { default as XPrimary } from './XPrimary.svg';
export { default as XBlue } from './XBlue.svg';

export { default as ExchangeCarBannerBackground } from './ExchangeCarBannerBackground.svg';
export { default as ArrowDownPrimaryWithTail } from './arrow-down-primary.svg';
export { default as ArrowDownBlueWithTail } from './arrow-down-blue.svg';
export { default as GreenInfo } from './greenInfo.svg';
export { default as BlueInfo } from './blue-info.svg';
export { default as ExpandImage } from './expand-image.svg';
export { default as ExtendImage } from './extend-image.svg';
export { default as ChevronRightWhite } from './chevron-right-white.svg';
export { default as ChevronLeftWhite } from './chevron-left-white.svg';
export { default as DarkInfo } from './dark-info.svg';
export { default as Polygon } from './Polygon.svg';

export { default as HubIcon } from './hubIcon.svg';
export { default as HubIconGreen } from './hubIconGreen.svg';
export { default as HomeIcon } from './homeIcon.svg';
export { default as HomeIconGreen } from './homeIconGreen.svg';
export { default as HomeIconGray } from './homeIconGray.svg';

export { default as coins } from './coins-stacked.svg';

////
export { default as IssueIllustration } from './IssueIllustration.svg';
export { default as Help } from './Help.svg';
export { default as ChevronRight } from './chevronRight.svg';
export { default as ForwardWhite } from './ForwardWhite.svg';
export { default as CalendarBookedCar } from './calendarBookedCar.svg';
export { default as CalendarBookedCar2 } from './calendar2.svg';

export { default as PlusGreenIcon } from './plus-green-icon.svg';
export { default as NavigatorIcon } from './navigation-pointer.svg';
export { default as PhoneCallIcon } from './phone-call-icon.svg';
export { default as BookingsBlackMarker } from './booking-marker-black-icon.svg';
export { default as LocationFrame } from './locationFrame.svg';
export { default as LocationIcon } from './geoLocationButton.svg';

export { default as CarFinance } from './car-finance.svg';

export { default as GreenTick } from './greenTick.svg';
export { default as CheckBoxCheck } from './checkBoxCheck.svg';
export { default as ArrowDown } from './ArrowDown.svg';
export { default as ArrowDarkDown } from './arrowDownDark.svg';
export { default as CloseButtonIcon } from './closeButton.svg';
export { default as BookingClockIcon } from './bookings-clock-icon.svg';
export { default as BookingLocationIcon } from './booking-location.svg';
export { default as BookingCallIcon } from './booking-call-icon.svg';
export { default as BookingWhiteCallIcon } from './booking-white-call-icon.svg';
export { default as WhiteNavigatorIcon } from './navigation-pointer-white-icon.svg';
export { default as BookingArrowDown } from './booking-arrow-up.svg';
export { default as BookingArrowUp } from './booking-arrow-down.svg';
export { default as MyBookingCoverPhoto } from './booking-cover-photo.jpeg';
export { default as LogsCarIcon } from './logs-car-icon.svg';
export { default as LogsShield } from './logs-shield.svg';
export { default as PlusWhiteIcon } from './plus-white-icon.svg';
export { default as phoneIconWhite } from './phone-call-white.svg';
export { default as ChevronRightArrowBlack } from './chevron-right-black.svg';
export { default as ChevronRightArrowGreen } from './right-arrow-green.svg';
export { default as InstapayLogo } from './instapay-logo.svg';
export { default as BankIcon } from './bank-icon.svg';
export { default as BankChequeIcon } from './bank-cheque-icon.svg';
export { default as InfoCircleBlack } from './info-circle-icon.svg';
export { default as CIBlogo } from './cib-logo.svg';
export { default as NBElogo } from './NBE-logo.svg';
export { default as BankMisrLogo } from './banque-misr-logo.svg';
export { default as EGBankLogo } from './EGBANK-logo.svg';
export { default as PaymentLogSubmittonError } from './paymentlog-submition-error-.svg';
export { default as CopyIcon } from './copy-icon.svg';
export { default as InfoGreenIcon } from './info-circle-green.svg';
export { default as InfoGrayIcon } from './info-circle-gray.svg';
export { default as ChevronLeft } from './chevron-left.svg';

export { default as chevronUpArrowBlack } from './chevron-up-arrow-black.svg';
export { default as ArrowsGroupUploadPayment } from './arrows-group-upload-payment.svg';
export { default as RedArrowRightUp } from './red-arrow-right-up.svg';
export { default as BankChequeThinIcon } from './bank-cheque-icon-thin.svg';
export { default as UploadPaymentIcon } from './upload-payment-icon.svg';
export { default as UploadPaymentInfoIcon } from './upload-payment-info-icon.svg';
export { default as PaymentLogUploadedIcon } from './payment-log-uploaded-icon.svg';
export { default as PaymentLogReuploadFileIcon } from './payment-log-reupload-icon.svg';
export { default as PaymentLogDeleteFileIcon } from './payment-log-delete-image-icon.svg';
export { default as PaymentLogUploadFileErrorIcon } from './payment-log-uploading-image-error.svg';
export { default as PaymentLogXIcon } from './payment-log-x-circle.svg';
export { default as Filter } from './Filter.svg';
export { default as FilterActive } from './FilterActive.svg';
export { default as SortIcon } from './FilterIcon.svg';
export { default as SortIconActive } from './SortIconActive.svg';

export { default as ArrowRightDark } from './arrowRightDark.svg';
export { default as ChevronDownArroy } from './chevron-down.svg';
export { default as HelpIconBlack } from './help-icon-black.svg';
export { default as DashedGrayLine } from './dashed-gray-line.svg';
export { default as DashedGrayLineMobile } from './dashed-gray-line-mobile.svg';
export { default as NotTemperedIcon } from './not-tempered-icon.svg';
export { default as NoAccidentsIcon } from './no-accidents.svg';
export { default as InspectionPointsIcon } from './inspection-points.svg';
export { default as CalendarHalfGreenCheckedIcon } from './calendar-half-green-checked.svg';

export { default as LegalitiesCheckedIcon } from './legalities-icon-checked.svg';
export { default as PriceBenefitsGif } from './price-benefits.gif';
export { default as ZeroRepairCostIcon } from './zero-repair-cost-icon.svg';
//reserve see car
export { default as CarGarage } from './ReserveSeeCar/carGarage.svg';
export { default as Cross } from './ReserveSeeCar/cross.svg';
export { default as HomeTestDrive } from './ReserveSeeCar/homeTestDrive.svg';
export { default as Money } from './ReserveSeeCar/money.svg';
export { default as OrangeMoney } from './ReserveSeeCar/orangeMoney.svg';
export { default as SeeCarIcon } from './ReserveSeeCar/SeeCarIcon.svg';
export { default as ShieldTick } from './ReserveSeeCar/shieldTick.svg';
export { default as Tick } from './ReserveSeeCar/tick.svg';
export { default as See } from './ReserveSeeCar/SeeCarIcon.png';
export { default as ShieldTickModal } from './ReserveSeeCar/shieldTick.png';
export { default as InspectionHubStars } from './inspection-hub-stars.svg';

// Value Proposition

export { default as RightArrow } from './valueProposition/carousel_arrow_right.svg';
export { default as LeftArrow } from './valueProposition/carousel_arrow_left.svg';
export { default as FloatingButton } from './valueProposition/floatingButton.svg';

export { default as see } from './ReserveSeeCar/SeeCarIcon.png';
export { default as shieldTickModal } from './ReserveSeeCar/shieldTick.png';
////

export { default as AlertCircle } from './alertCircle.svg';
export { default as AlertClose } from './alertClose.svg';
export { default as Success } from './snackbarSuccess.svg';
export { default as AlertCloseSuccess } from './AlertCloseSuccess.svg';
export { default as BankNote } from './bank-note.svg';

// Value Proposition
export { default as Ar200 } from './valueProposition/200-ar.png';
export { default as Ar7 } from './valueProposition/7-ar.png';
export { default as Ar90 } from './valueProposition/90-ar.png';
export { default as ArPercentage } from './valueProposition/_.ar.png';
export { default as En200 } from './valueProposition/200-e.png';
export { default as En7 } from './valueProposition/7.e.png';
export { default as En90 } from './valueProposition/90-e.png';
export { default as EnPercentage } from './valueProposition/_-e.png';

export const banners = [
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_EN-01.jpg',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_EN-02.jpg',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_EN-03.jpg',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_EN-04.jpg',
    },

    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_EN-06.jpg',
    },
];

export const bannersAR = [
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_AR-01.png',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_AR-02.png',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_AR-03.png',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_AR-04.png',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_AR-05.png',
    },
    {
        image: 'https://website-images.sylndr.com/Product%20Proposition_AR-06.png',
    },
];
export const imgs = [
    'https://fastly-production.24c.in/hello-ar/dev/1920/912028f8-bc14-4921-bf2b-10b7dadc8738/f8254072-57fb-4310-ab4a-05a932b8239f/1.jpg',
    'https://fastly-production.24c.in/hello-ar/dev/1920/912028f8-bc14-4921-bf2b-10b7dadc8738/f8254072-57fb-4310-ab4a-05a932b8239f/13.jpg',
    'https://fastly-production.24c.in/hello-ar/dev/1920/912028f8-bc14-4921-bf2b-10b7dadc8738/f8254072-57fb-4310-ab4a-05a932b8239f/24.jpg',
    'https://fastly-production.24c.in/hello-ar/dev/1920/912028f8-bc14-4921-bf2b-10b7dadc8738/f8254072-57fb-4310-ab4a-05a932b8239f/41.jpg',
    'https://fastly-production.24c.in/hello-ar/dev/1920/912028f8-bc14-4921-bf2b-10b7dadc8738/f8254072-57fb-4310-ab4a-05a932b8239f/44.jpg',
    'https://fastly-production.24c.in/hello-ar/dev/1920/912028f8-bc14-4921-bf2b-10b7dadc8738/f8254072-57fb-4310-ab4a-05a932b8239f/33.jpg',
];

export { default as Valu } from './valu.jpg';
export { default as QNB } from './QNB.png';
export { default as GlobalCorp } from './global-corp.png';
export { default as AiBank } from './ai-bank.svg';
export { default as ComparisonIcon } from './table.svg';

export { default as AdminFeesIcon } from './adminFeesIcon.png';
export { default as ApprovalDuration } from './check.svg';
export { default as DocumentsNeeded } from './documentsNeeded.svg';
export { default as FromDownPayment } from './fromDownPayment.svg';
export { default as UpToYears } from './upToYears.svg';

export { default as StepPersonalInformationImg } from './create-alert/personalInfollustration.svg';
export { default as CreateAlertBellIcon } from './create-alert/create-alert-bell.svg';
export { default as CreateAlertThankYouGif } from './create-alert/create-alert-thank-you.gif';
export { default as AllowLocation } from './AllowLocation.svg';

export { default as ErrorIcon } from './create-alert/error-icon.svg';
export { default as CashIcon } from './create-alert/cash-icon.svg';
export { default as FinancingIcon } from './create-alert/financing-icon.svg';
export { default as SedanType } from './create-alert/sedan-icon.svg';
export { default as HatchbakType } from './create-alert/hatchback-icon.svg';
export { default as TruckType } from './create-alert/truck-icon.svg';
export { default as SUVType } from './create-alert/suv-icon.svg';
export { default as NoSearchedCarsFound } from './create-alert/no-cars-match.svg';
export { default as ArrowUpSmall } from './create-alert/arrow-up-small.svg';
export { default as CreateAlertCheckBoxGreenTick } from './create-alert/create-alert-checkbox-tick.svg';
export { default as GreenCloseIcon } from './create-alert/green-close-Icon.svg';
export { default as CloseIcon } from './create-alert/close-icon.svg';

export { default as TestDriveIcon } from './create-alert/test-drive-icon.svg';
export { default as RecieveSmsIcon } from './create-alert/recieve-sms-icon.svg';
export { default as AddCarDetailsIcon } from './create-alert/add-cardetails-icon.svg';

export { default as StepCarDetailsImg } from './create-alert/carDetailsIllustration.svg';
export { default as CreateAlertArrow } from './create-alert/create-alert-arrow-right.svg';
export { default as RightGreenTagArrow } from './create-alert/right-green-icon.svg';
export { default as StepPersonalInformationIcon } from './create-alert/stepPersonalInformation.svg';
export { default as StepCarDetailsIcon } from './create-alert/stepCarDetails.svg';
export { default as ThankYouIllustration } from './create-alert/thankYouIllustration.svg';
export { default as CantFindCarEntryPointHeader } from './create-alert/cantFindCarEntryPoint.svg';
export { default as BackIcon } from './create-alert/backButton.svg';
export { default as ArrowDownGrey } from './arrowDownGrey.svg';

export { default as Calculater } from './calculator.svg';
export { default as CheckVerified } from './checkVerified.svg';
export { default as Papers } from './papers.svg';
export { default as ArrowDiagonal } from './ArrowDiagonal.svg';
export { default as GreenCalendar } from './greenCalendar.svg';
export { default as LocationPin } from './locationPin.svg';
export { default as LocationPinDark } from './locationPinDark.svg';

export { default as NavBack } from './navBack.svg';
export { default as Calendar } from './calendar.svg';
export { default as CalendarCheck } from './calendar-check.svg';
export { default as MarkerPin } from './marker-pin.svg';
export { default as GearBox } from './Gearbox.svg';

export { default as StepperBaseIcon } from './StepperBaseIcon.svg';
export { default as StepperDoneIcon } from './StepperDoneIcon.svg';
export { default as StepperInactiveIcon } from './StepperInactiveIcon.svg';

export { default as GreenRightArrow } from './greenRightArrow.svg';
export { default as StarRibbon } from './starRibbon.svg';

///thank you checkout
export { default as AiBankSvg } from './finance_img/ai-bank.svg';
export { default as GreenCheck } from './finance_img/greenCheck.svg';
export { default as ThankYouImage } from './ThankYouImage.svg';
export { default as ValuSvg } from './finance_img/ValuSVG.svg';

//not-found
export { default as CantFindCar } from './not-found/cant-find-car.svg';
export { default as ArrowLeft } from './not-found/arrow-left.svg';
export { default as PlusCircle } from './plus-circle.svg';
export { default as PlusCircleGreen } from './plus-circle-green.svg';
export { default as PlusBoldCircleGreen } from './plus-bold-circle.svg';
export { default as PlusCircleGray } from './plus-circle-gray.svg';

export { default as MinusCircle } from './minus-circle.svg';
export { default as LocationNotAllowed } from './LocationNotAllowed.svg';
export { default as AltertTriangle } from './alertTriangle.svg';
export { default as LockIcon } from './lockIcon.svg';
export { default as SearchIcon } from './search-lg.svg';
export { default as SearchIconDark } from './search-sm.svg';

export { default as ArrowRightUp } from './arrow-narrow-up-right.svg';
export { default as Star } from './star.svg';
export { default as TestimonialArrowLeft } from './testiomonialArrowLeft.svg';
export { default as TestimonialArrowRight } from './testiomonialArrowRight.svg';
export { default as BlueHomeIcon } from './blueHomeIcon.svg';
export { default as SylndrS } from './sylndrS.svg';
export { default as NewlyAddedEn } from './NewlyAddedEn.svg';
export { default as NewlyAddedAr } from './NewlyAddedAr.svg';
export { default as ExchangeCarBannerIcon } from './exchangeBannerIcon.8a694a82.png';

export { default as imgPlaceholder } from './imgPlaceholder.gif';

//sell car page

export { default as ArrowDownTwisted } from './arrow-down-twisted.svg';
export { default as HomeButton } from './HomeWhiteBG.svg';
export { default as SsylndrIcon } from './hubWhiteBG.svg';
export { default as circleCheck } from './check-circle.svg';
export { default as loadingCircle } from 'public/Images/LoadingCircle.svg';
export { default as loadingCircleDark } from 'public/Images/LoadingCircleDark.svg';

export { default as chevronGreenArrow } from './chevron-right.svg';
export { default as hintIcon } from './hint.svg';
export { default as locationArrow } from './locationArrow.svg';
export { default as directionsIcon } from './directionsIcon.svg';
export { default as underLine } from './underLine.svg';
export { default as ticket } from './ticket.svg';
export { default as homeIncpection } from './homeInspection.svg';
export { default as freeIcon } from './freeIcon.svg';
export { default as magnifireIcon } from './mgnifireIcon.svg';
export { default as zapFastIcon } from './zapFast.svg';
export { default as saleIcon } from './saleIcon.svg';
export { default as shieldIcon } from './shield.svg';
export { default as arrowAnimated } from './arrowAnimate.gif';
export { default as playButton } from './playButton.svg';
export { default as greenArrowAnimated } from './greenArrowAnimate.gif';
export { default as scrollIcon } from './scrollIcon.svg';
export { default as HassleFree } from './Acquisition/HassleFreeExperience.svg';
export { default as inspection } from './Acquisition/inspection.svg';
export { default as instantOffer } from './Acquisition/InstantOfferPayment.svg';
export { default as noHidden } from './Acquisition/NoHiddenCosts.svg';
export { default as sellPageBanner } from './Acquisition/sellPageBanner.png';
export { default as ShowRoom } from './Acquisition/ShowRoom.svg';
export { default as Voucher } from './Acquisition/Voucher.svg';
export { default as startIcon } from './starIcon.svg';
export { default as getLocation } from './getLocation.svg';

export { default as AlertIconGrey } from './alertIconGrey.svg';
export { default as trendingDown } from './trendingDown.svg'; // to be remove if not used.
export { default as generalHighlights } from './general-highlights.svg';
export { default as LimitedTimeOffer } from './LimitedTimeOffer.svg';
export { default as PaymentIcon } from './Payment.svg';
export { default as PaymentLinkExpired } from './PaymentLinkExpired.svg';
export { default as PaymentLinkSuccessful } from './PaymentLinkSuccessful.svg';
export { default as PaymentLinkUsed } from './PaymentLinkUsed.svg';
export { default as PhoneCall } from './phone-call.svg';

export { default as NoSellingRequests } from './NoSellingRequests.svg';

export { default as NoBuyingRequests } from './NoBuyingRequests.svg';
export { default as phoneIcon } from './phoneIcon.svg';
export { default as chevronDownGreen } from './chevronDownGreen.svg';
export { default as DoubleCurvedLine } from './curvedLine.svg';
export { default as EntryPointCarDetails } from './entry-point-car-details.png';
export { default as EntryPointCarListing1 } from './entry-point-car-listing-1.png';
export { default as EntryPointCarListing2 } from './entry-point-car-listing-2.png';
export { default as EntryPointCarListing3 } from './entry-point-car-listing-3.png';
export { default as EntryPointCarListing4 } from './entry-point-car-listing-4.png';
export { default as GetOnAppleStore } from './get-on-app-store.svg';
export { default as GetOnAndroidStore } from './get-on-android.svg';
export { default as GetOnHuaweiStore } from './get-on-huawei.svg';
export { default as GetAppBookingScreenBG } from './get-app-booking.svg';
export { default as GetAppBookingScreenImage } from './get-app-booking-img.png';
